import { navigate, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React from 'react'

const CoursePage = (props: PageProps) => {
	const { ShowDetails } = useGetCourseDetails(props.params.id)
	React.useEffect(() => {
		navigate('./activities')
	}, [])
	return <>{ShowDetails}</>
}

export default CoursePage
